body,
button,
input,
select,
textarea {
	color: $text_main;
	font-family: $font_main;
	font-size: 20px;
	line-height: $font_main_lineheight;
}

h1, h2, h3, h4, h5, h6 {
	clear: both;
	font-family: $font_main;
}


footer {
	background-color: pink;
}

img {
	max-width: 100%;
}

.error404-inner {
	padding-top: 80px;
	padding-bottom: 80px;
}
