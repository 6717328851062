a {
	color: $text_link;

	&:visited {
		color: $text_link_visited;
	}

	&:hover,
	&:focus,
	&:active {
		color: $text_link_hover;
	}

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}
}
