a.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a.more-link,
a.button:visited {
	color: $button_text;
	border: 1px solid darken( $button_background, 5% );
	background-color: $button_background;
	display: inline-block;
	height: 40px;
	padding: 0 10px;
	margin: 0;
	text-align: center;
	font-weight: 400;
	line-height: 38px;
	text-decoration: none;
	border-radius: 4px;
	cursor: pointer;
	box-sizing: border-box;
	font-size: 16px;
	@include mobile {
		font-size: 20px;
		padding: 0 15px;
	}

	&.fvp-donate {
		border-radius: 0;
		padding: 0 15px;
		margin: 0 8px;
		font-size: 18px;
		color: #fff;
		background-color: $button_donate;
		border: 1px solid darken( $button_donate, 5% );

		@include laptop {
			// margin-bottom: 10px;
		}

		&:hover,
		&:focus {
			background-color: darken( $button_donate, 5% );
			border-color: darken( $button_donate, 10% );
		}
	}

	&.fvp-clear-history {
		border-radius: 0;
		padding: 0 15px;
		margin-left: 8px;
		font-size: 18px;
		color: #fff;
		background-color: $background_strapline;
		border: 1px solid darken( $background_strapline, 5% );

		@include laptop {
			// margin-bottom: 10px;
		}

		&:hover,
		&:focus {
			background-color: darken( $background_strapline, 5% );
			border-color: darken( $background_strapline, 10% );
		}
	}

	&.fvp-escape {
		position: fixed;
		z-index: 5000;
		top: 10px;
		right: 15px;
		border-radius: 0;
		padding: 0 15px;
		margin-bottom: 0;
		font-size: 18px;
		color: #fff;
		background-color: $button_escape;
		border: 1px solid darken( $button_escape, 5% );
		text-transform: uppercase;

		@include mobile {
			padding: 0 30px;
		}

		@include desktop {
			right: 25px;
		}

		&:hover,
		&:focus {
			background-color: darken( $button_escape, 5% );
			border-color: darken( $button_escape, 10% );
		}
	}
}

a.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
a.more-link:hover,
a.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus,
a.more-link:focus {
	color: $button_text;
	border-color: darken( $button_background, 10% );
	background-color: darken( $button_background, 5% );
	outline: 0;
	text-decoration: none;
}

a.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
	color: $button_primary_text;
	border: 1px solid darken( $button_primary_background, 5% );
	background-color: $button_primary_background;
}

a.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
a.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
	color: $button_primary_text;
	border-color: darken( $button_primary_background, 10% );
	background-color: darken( $button_primary_background, 5% );
	outline: 0;
	text-decoration: none;
}

button,
a.button {
	margin-bottom: 1rem;
}

a.more-link {
	margin-top: 1rem;
	display: block;
	max-width: 180px;
}
