.site-header {
    background-color: $background_header;

    padding: 10px 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;


    .container {
    	padding: 0 15px;
		width: 100%;

    	@include desktop {
			padding: 0 25px;
    	}
    }


	.logo {
    position: relative;
    z-index: 100;
    .logo-bkg {
      background: #fff;
      display: inline-block;
      padding: 30px;
      border-bottom-right-radius: 80px;
      margin-left: -40px;
      margin-top: -30px;
    }

		a {

			img {
				max-width: 120px;

				@include laptop {
					max-width: 184px;
					margin-left: 5px;
				}

				@include desktop {
					max-width: 236px;
					margin-left: 8px;
				}
			}

			span{
    			font-size: 11px;

				@include laptop {
					font-size: 14px;
				}

				@include desktop {
					font-size: 18px;
				}
			}
		}
	}

	.menu-container {
	    text-align: right;
      position: static;

	    .lgbtq-icon {
	    	display: none;

	    	@include tablet {
				display: inline-block;
				width: 65px;
				height: 40px;
				padding: 0;
				margin: 0;
	    	}
	    }
	}

	.escape-container {
		position: absolute;
		width: auto;
		height: 40px;
		top: 10px;
		right: 116px;
		overflow: hidden;

		@include laptop {
			position: relative;
			width: 100%;
			padding-right: 101px;
			margin-bottom: 10px;
			top: auto;
			right: auto;
		}
	}


	.hero-menu-block {
		// overflow: hidden;
		margin-right: -15px;
		padding-right: 15px;

		@include desktop {
			margin-right: -25px;
			padding-right: 25px;
		}
	}

	.navbar-right {
		position: absolute;
    margin-right: 0;
	    right: 0;
	    padding-left: 15px;
	    padding-right: 75px;
	    background-color: #fff;
		-webkit-border-top-left-radius: 25px;
			-moz-border-radius-topleft: 25px;
				border-top-left-radius: 25px;
		-webkit-border-bottom-left-radius: 25px;
			-moz-border-radius-bottomleft: 25px;
				border-bottom-left-radius: 25px;

		@include desktop {
	    	padding-left: 20px;
			-webkit-border-top-left-radius: 32px;
				-moz-border-radius-topleft: 32px;
					border-top-left-radius: 32px;
			-webkit-border-bottom-left-radius: 32px;
				-moz-border-radius-bottomleft: 32px;
					border-bottom-left-radius: 32px;
		}
	}
}

.logo {

	a {
		color: #000;
		display: inline-block;

		&:hover,
		&:focus {
			color: #000;
			text-decoration: none;
		}
	}

	img {
		display: block;
	}
}
