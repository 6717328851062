// Grid system
.main {
  // @include make-sm-column($main-sm-columns);
	position: relative;
	min-height: 1px;

  .sidebar-primary & {
    // @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  // @include make-sm-column($sidebar-sm-columns);
}
