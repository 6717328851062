.post {
  margin-top: 30px;
  h3 {
    margin-bottom: 4px;
  }
  .time {
    font-size: 18px;
  }
  .entry-summary {
    p {
      font-size: 18px;
    }
    a:last-of-type {
     display: block;
     margin-top: 20px;
     width: 130px;
   }
  }
}
