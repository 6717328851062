.hero, .interior-hero {
    background-image: url('/wp-content/uploads/haj-hero.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    position: relative;

    p {
        font-size: 28px;
        line-height: 1.5em;
        margin-bottom: 1em;

        @include tablet {
            font-size: 36px;
        }
    }
}

.hero {
    padding: 180px 0 60px;

    @include mobile {
        padding: 200px 0 80px;
    }

    @include tablet {
        padding: 280px 0 60px;
    }

    @include laptop {
        padding: 280px 0 60px;
    }

    @include desktop {
        padding: 280px 0 260px;
    }
}

.interior-hero {
    padding: 40px 0;
    min-height: 160px;
    @include mobile {
        padding: 80px 0;
    }

    @include tablet {
        padding: 120px 0;
    }

    @include laptop {
        padding: 160px 0;
    }

    @include desktop {
        padding: 200px 0;
    }
}





.hero-circle {
    background-color: $background_strapline;
    color: $text_strapline;
    border-radius: 50%;
    margin: 0 auto;
    width: 100%;

    @include aspect-ratio(1, 1);

    max-width: 250px;

    @include tablet {
        max-width: 360px;
    }

    .hero-inner-circle {
        padding: 64px 0 0;

        @include tablet {
            padding: 92px 0 0;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.3em;
        margin-bottom: 28px;

        @include tablet {
            font-size: 22px;
            margin-bottom: 48px;
        }
    }
}

a.button.hero-button {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    border-color: transparent;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    text-decoration: none;
    padding: 0;

    @include tablet {
        font-size: 25px;
        line-height: 25px;
    }

    &:hover,
    &:focus {
        border-color: transparent;
        border-bottom: 1px solid #fff;
        background-color: transparent;
    }
}

.strapline {
    padding: 35px 0;
    background-color: $background_strapline;
}

.strapline p {
    color: $text_strapline;
    text-align: center;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 1.5em;

    @include desktop {
        font-size: 25px;
    }
}
