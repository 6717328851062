.site-footer {
    border-top: 1px solid #eee;
    padding: 20px 0;
    background-color: $background_footer;

    @include laptop {
        padding: 60px 0;
    }

    .logo {
        text-align: center;

        img {
            margin: 15px auto;
            width: 100%;
            max-width: 300px;

            @include laptop {
                margin: 0;
            }
        }
    }

    .social-media {
      .fa {
        font-size: 30px;
      }
      ul {
        margin-top: 10px;
        text-align: center;
        padding-left: 0;
        list-style-type: none;
        li {
          display: inline-block;
          margin-right: 5px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        @include laptop {
          margin-top: 30px;
          text-align: left;
          li {
            display: block;
          }
        }
      }
    }

    p {
        text-align: center;
        font-size: 18px;
        line-height: 21px;
        margin-top: 12px;
        margin-bottom: 0;
    }

    .menu-container { // Override Boostrap nav
        ul {

            @include tablet { // center footer nav on tablets
                float: none !important;
                text-align: center;
                margin-right: 0;
            }

            @include laptop { // set footer nav back to normal
                float: right !important;
                margin-right: -15px;
            }
        }

        li {

            @include tablet { // center footer nav on tablets
                float: none;
                display: inline-block;
            }

            @include laptop { // set footer nav back to normal
                float: left;
                display: block;
                width: 100%;
            }

            a {
                text-align: center;
                font-size: 18px;
                padding-left: 10px;
                padding-right: 10px;

                @include laptop {
                    text-align: left;
                    padding: 5px 0;
                }
            }
        }
    }
}

.nav-container {
    margin: 24px 0;
}

.disclaimer {
  background-color: #E4E5E7;
  padding: 15px 0;
  text-align: center;

  p {
    font-size: 12px;
    color: #000;
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.copyright {
    background-color: $background_copyright;
    color: #fff;
    font-size: 12px;
    padding: 15px 0;
    text-align: center;

    @include laptop {
        text-align: left;

        .footer-right span {
            float: right;
        }
    }

    span {
        font-size: 12px;
    }

    a {
        color: #fff;
        font-size: 12px;

        &:hover {
            text-decoration: underline;
        }
    }
}
