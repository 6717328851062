/*--------------------------------------------------------------
## Primary navigation
--------------------------------------------------------------*/
.nav {
	li {
		a {
			color: $nav_main;

			&:focus,
			&:hover {
				color: $nav_hover;
			    text-decoration: none;
			    background-color: transparent;
			}
		}
	}
}

// get rid of Bootstrap border and box shadow
.navbar-collapse {
	border-top: none;
	-webkit-box-shadow: none;
			box-shadow: none;
}

#navbar-collapse-1,
#navbar-collapse-2 {
	padding-left: 0;
}

#navbar-collapse-4 {
	padding: 0;
}

.comment-navigation,
.posts-navigation,
.post-navigation {

	.site-main & {
		margin: 0 0 1.5em;
		overflow: hidden;
	}

	& .nav-previous {
		float: left;
		width: 50%;
	}

	& .nav-next {
		float: right;
		text-align: right;
		width: 50%;
	}
}

#menu-main-menu {
	a {
		@include laptop {
			padding: 15px 10px;
			font-size: 14px;
		}
		@include desktop {
			padding: 22px 10px;
			font-size: 18px;
		}
		@include hdtv {
			padding: 22px 12px;
			font-size: 22px;
		}
	}

	.dropdown-menu {
		background-color: #fff;
		// box-shadow: none;
		border: none;
		left: 8px;
		right: auto;
		// max-width: 400px;
		li {
			// max-width: 100%;
			a {
				color: $background_strapline;
				font-size: 16px;
		    white-space: normal;
				padding: 5px 10px;
				&:hover, &:focus {
					color: #000;
				}
			}
		}
	}
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
	background-color: transparent;
	border-color: darken( $background_header, 5% );
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
	color: #fff;
	text-decoration: none;
	background-color: darken( $background_header, 5% );
	outline: 0;
}

@include tablet {
	.navbar-right .dropdown-menu {
		right: 0;
		left: auto;

		li a {
			padding-top: 6px;
			padding-bottom: 6px;
			font-size: 16px;
		}
	}
}

/*--------------------------------------------------------------
## Side navigation
--------------------------------------------------------------*/
.side-navigation {
	.nav {
		margin: 0;
		width: 100%;
	}

	.navmenu-default,
	.navbar-default .navbar-offcanvas {
	    border-color: $nav_border;
	    background: $background_strapline;
	    background-repeat: no-repeat;
	    background-position: top left;
	    background-size: cover;
	}

	img {
		display: block;
		margin: 15px auto 5px;
	}

	li {
		width: 100%;

		a {
			color: $text_strapline;
			font-size: 18px;
			padding: 12px 0 12px 20px;
			font-weight: 400;

			&:focus,
			&:hover {
				color: lighten( $background_strapline, 50% );
				text-decoration: none;
			    background-color: transparent;
			}
		}
	}

	.sidenav-buttons .fvp-donate {
		margin: 10px 20px;
	}
}

@media (max-width: 991px) {
	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.navbar-nav .open .dropdown-menu .dropdown-header,
	.navbar-nav .open .dropdown-menu>li>a {
		padding: 10px 0 10px 35px;
		line-height: 20px;
	}
}

.canvas-slid {
	right: 245px;
}

.navmenu, .navbar-offcanvas {
	width: 245px;
	overflow-x: hidden;
}



/* Footer Facebook Icon */
 li#menu-item-69 a {
    position: relative;
    color: transparent;
}

li#menu-item-69 a:before {
    content: "\f082";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    color: $button_facebook;
    font-size: 24px;
    padding-right: 0.5em;
    position: absolute;
    top: 6px;
    left: 0;
}
