@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,200,300,600);


// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Fonts
$font_main: 'Titillium Web', sans-serif;
$font_main_lineheight: 1.4;

// Colors
$brand-primary:         #27ae60;

// Background colors
$background_body: #fff;
$background_screen: #f1f1f1;
$background_hr: #ccc;
$background_pre: #eee;
$background_ins: #fff9c0;
$background_header: transparent;
$background_strapline: #492f91;
$background_footer: #fff;
$background_copyright: #2e2e2e;
$background_blog_feature: #efefef;

// Text colors
$text_main: #492f91;
$text_strapline: #fff;

// Navigation colors
$nav_main: #000;
$nav_hover: #492f91;

// Link colors
$text_link: royalblue;
$text_link_visited: purple;
$text_link_hover: midnightblue;
$text_screen: #21759b;
$text_input: #666;
$text_input_focus: #111;

// form borders
$form_border: #d1d1d1;
$border_input: #ccc;
$border_abbr: #666;
$map_border: #ddd;
$nav_border: #e7e7e7;

// buttons, hamburger, scrollup colors
$button_text: #fff;
$button_background: #492f91;
$button_primary_text: #492f91;
$button_primary_background: #fff;
$hamburger_normal: #492f91;
$hamburger_active: #573f98;
$scrollup_border: rgba(255, 255, 255, 0.5);
$button_escape: #ef1f1f;
$button_donate: #98d57d;
$button_facebook: #3b5998;

// Media query variables
$width_mobile: 480px;
$width_phablet: 560px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;
$width_hdtv: 1500px;

// Media query mixins - @include mobile { selector: style; }
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin hdtv { @media (min-width: #{$width_hdtv}) { @content; } }

// Aspect ratio - @include aspect-ratio(16, 9);
@mixin aspect-ratio($ratio-or-width, $height: null) {
	$padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
	&:before { content:''; float: left; padding-bottom: $padding; }
	&:after  { content:''; display: table; clear: both; }
}
