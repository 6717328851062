input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	color: $text_input;
	border: 1px solid $border_input;
	border-radius: 4px;

	&:focus {
		color: $text_input_focus;
	}
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
	padding: 3px;
}

textarea {
	padding-left: 3px;
	width: 100%;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	height: 38px;
	padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
	background-color: #fff;
	border: 1px solid $form_border;
	border-radius: 4px;
	box-shadow: none;
	box-sizing: border-box;
}

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; 
}

textarea {
	min-height: 65px;
	padding-top: 6px;
	padding-bottom: 6px; 
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
	border: 1px solid $button_background;
	outline: 0; 
}

label,
legend {
	display: block;
	margin-bottom: .5rem;
	font-weight: 600; 
}

fieldset {
	padding: 0;
	border-width: 0; 
}

input[type="checkbox"],
input[type="radio"] {
 	display: inline; 
}

label > .label-body {
	display: inline-block;
	margin-left: .5rem;
	font-weight: normal; 
}

input,
textarea,
select,
fieldset {
	margin-bottom: 1.5rem; 
}

/* Styles specific to Gravity Forms */
.gform_wrapper {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;	

		li.gfield {
			margin-bottom: 0.5em;
		}
	}

	input[type=text],
	input[type=email],
	input[type=tel],
	textarea {
		width: 100%;
		border-radius: 4px;
	}

	.g-recaptcha {
		margin-bottom: 30px;
	}
}

// 404 page search form
.search-form {
	label {
	    display: inline-block;
	}

	input[type="submit"] {
		height: 38px;
		line-height: 36px;
	}
}

/* Keep CAPTCHA from breaking mobile layout */
@media screen and (max-width: 479px) {
	.g-recaptcha > div > div {
		border-right: 1px solid #d3d3d3;
		width: 273px !important;
		border-radius: 4px;
	}
}