/*--------------------------------------------------------------
## Content area
--------------------------------------------------------------*/
.content-area {
    padding-bottom: 60px;
    p {
      margin-bottom: 20px;
    }
}


/*--------------------------------------------------------------
## Subfeatures
--------------------------------------------------------------*/
.single-feature {
    padding: 80px 0;
    text-align: center;

    img {
        max-width: 100%;
        margin: 10px auto 20px;
        border-radius: 50%;
        border: 3px solid $background_strapline;
    }

    &.textured-background {
        background-image: url('/wp-content/uploads/feature-bg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .post {
      .time {
        font-size: 18px;
      }
      .entry-summary {
        p {
          max-width: 480px;
          margin: 0 auto 10px;
        }
        a:last-of-type {
         margin: 20px auto 0;
       }
      }
    }
}

.double-feature {
    background-image: url('/wp-content/uploads/purple-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding: 80px 0;

    color: $text_strapline;
}

#socialMedia {
  .fa {
    font-size: 40px;
    margin-right: 15px;
  }

}

.facebook {
  color: #3b5998;
}
.twitter  {
  color: #55acee;
}
.instagram {
  color: #000;
}

.resource-section {
  margin-bottom: 40px;
  h3 {
    margin-bottom: 20px;
  }
}

.resource-logo {
  display: block;
  text-align: center;
  position: relative;
  margin: 10px auto;
  overflow: visible;

  .resource-image {
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 991px) {
      background-color: $background_strapline;
      background-image: none!important;
    }
  }

  img {
    margin: 0 auto;
  }

  .hover {
    background: $background_strapline;
    font-size: 16px;
    z-index: 100;
    text-align: center;
    transition: 0.5s;
    padding: 10px;
    color: #fff;
  }

  @include mobile {
    &.no-img {
      background: $background_strapline;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  @include tablet {
    height: 160px;
    .resource-image {
      height: 160px;
    }
  }
  @include laptop {
    min-height: 0;

    .hover {
      font-size: 18px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.5);
      visibility: hidden;
      opacity: 0;
    }

    &.no-img {
      .hover {
        position: relative;
        visibility: visible;
        opacity: 1;
        box-shadow: none;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    &:hover, &:focus {
      .hover {
        visibility: visible;
        opacity: 1;
      }
    }
  }

}

.expandable-content {
  .full-content {
    // transition: max-height 2s;
    // overflow: hidden;
  }
  // &.closed {
  //   .full-content {
  //     max-height: 0;
  //   }
  // }

  // &.opened {
  //   .full-content {
  //     max-height: 10000px;
  //   }
  // }
}
